import { PhoneNumber } from 'expo-contacts';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import Avatar, { AvatarProps } from '@components/Avatar/Avatar';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { RestyleProps } from '@components/shared/Icon/RestyleIcon';
import { File, useFriendRequestMutation } from '@graphql/generated';
import useMe from '@hooks/useMe';
import theme from '@themes/theme';

export type ContactType = {
  firstName?: string;
  lastName?: string;
  name?: string;
  company?: string;
  phoneNumber?: string;
  isPhoneContact?: boolean;
  id: string;
  allPhoneNumbers?: PhoneNumber[];
  initials: string;
  displayName: string;
  roleLabel?: string;
  avatar?: File | null;
  avatarSize?: AvatarProps['size'];
  email?: string;
  friendRequestPending?: boolean;
};

interface ContactProps {
  contact: ContactType;
  filterVal?: string;
  onPress: (item: ContactType) => void;
  isEditing?: boolean;
  isSelected?: boolean;
  isInviting?: boolean;
  shouldDisplayPhoneContactLabel?: boolean;
  disabled?: boolean;
  showMessageIcon?: boolean;
  onMessagePress?: (item: ContactType) => void;
  style?: RestyleProps;
  isTaskTagUser?: boolean;
  toggelFriendRequestSuccess?: (friendRequest: boolean) => void;
}

const Contact: React.FC<ContactProps> = ({
  contact,
  onPress,
  filterVal = '',
  isEditing,
  isSelected,
  isInviting,
  shouldDisplayPhoneContactLabel = true,
  disabled = false,
  showMessageIcon = false,
  onMessagePress,
  isTaskTagUser,
  toggelFriendRequestSuccess,
  style = { paddingHorizontal: 'm' },
}) => {
  const { t } = useTranslation('models');
  const { t: f } = useTranslation('format');
  const { inviteViaPhone } = useMe();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isHoveredText, setIsHoveredText] = useState<boolean>(false);
  const [isHoveredSendMsg, setIsHoveredSendMsg] = useState<boolean>(false);

  const {
    id,
    avatar,
    phoneNumber,
    isPhoneContact,
    displayName,
    roleLabel,
    friendRequestPending,
    avatarSize = 'medium',
  } = contact;

  const [friendRequest] = useFriendRequestMutation({
    refetchQueries: ['listUsers'],
  });

  const onPressAdd = () => {
    toggelFriendRequestSuccess &&
      toggelFriendRequestSuccess(statusBtn !== 'Pending');
    if (id) {
      friendRequest({
        onCompleted: () => {
          setTimeout(() => {
            toggelFriendRequestSuccess && toggelFriendRequestSuccess(false);
          }, 500);
        },
        variables: {
          attributes: {
            userIds: [id],
          },
        },
      });
    }
  };

  const [statusBtn, setStatusBtn] = useState('');

  useEffect(() => {
    if (friendRequestPending) setStatusBtn('Pending');
    else setStatusBtn('Add');
  }, [friendRequestPending]);

  return (
    <TouchableOpacity
      onPress={() => onPress(contact)}
      disabled={disabled}
      accessibilityLabel={displayName}>
      <Box
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        backgroundColor={isHovered ? 'grey01' : 'white'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...style}>
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'>
          {isEditing && (
            <Box justifyContent='center' marginRight='xs'>
              <RadioButton
                onPress={() => onPress && onPress(contact)}
                isSelected={!!isSelected}
                iconVariant='m'
                disabled={disabled}
              />
            </Box>
          )}
          <Avatar
            id={id}
            label={displayName}
            avatar={avatar}
            size={avatarSize}
            disableAvatarPress={showMessageIcon}
          />
          <Box marginLeft='xs'>
            <Text
              accessibilityLabel='Contact title'
              variant='webBodySecondary'
              color='textPrimary'
              textDecorationLine={isHoveredText ? 'underline' : undefined}
              onMouseEnter={() => setIsHoveredText(true)}
              onMouseLeave={() => setIsHoveredText(false)}>
              {displayName ? (
                <Highlighter
                  highlightStyle={{
                    backgroundColor: theme.colors.yellowBright,
                  }}
                  autoEscape
                  searchWords={filterVal?.split(' ') || ''}
                  textToHighlight={displayName}
                />
              ) : (
                'No name'
              )}
            </Text>
            {roleLabel && (
              <Text
                marginTop='xxs'
                variant='bodySecondary'
                color='textSecondary'>
                {t(roleLabel)}
              </Text>
            )}
            {!roleLabel && isPhoneContact && shouldDisplayPhoneContactLabel && (
              <Text
                marginTop='xxs'
                variant='bodySecondary'
                color='textSecondary'>
                From phone contact
              </Text>
            )}
            {isInviting && (
              <Text
                variant='bodySecondary'
                color='textSecondary'
                marginTop='xxs'>
                {f('phoneNumber', {
                  val: phoneNumber,
                })}
              </Text>
            )}
          </Box>
        </Box>
        {isTaskTagUser &&
          (friendRequestPending ? (
            <Box
              style={{
                paddingHorizontal: theme.spacing.s,
                paddingVertical: theme.spacing.xxs,
                minWidth: 70,
                backgroundColor: theme.colors.grey02,
                alignItems: 'center',
                borderRadius: theme.borderRadii.s,
              }}>
              <Text variant='buttonLabel' color='black'>
                {statusBtn}
              </Text>
            </Box>
          ) : (
            <TouchableOpacity onPress={onPressAdd}>
              <Box
                style={{
                  paddingHorizontal: theme.spacing.s,
                  paddingVertical: theme.spacing.xxs,
                  minWidth: 70,
                  backgroundColor: 'black',
                  alignItems: 'center',
                  borderRadius: theme.borderRadii.s,
                }}>
                <Text variant='buttonLabel' color='white'>
                  {statusBtn}
                </Text>
              </Box>
            </TouchableOpacity>
          ))}
        {isPhoneContact && phoneNumber && shouldDisplayPhoneContactLabel && (
          <TouchableOpacity onPress={() => inviteViaPhone(phoneNumber)}>
            <Box
              borderColor='textPrimary'
              borderWidth={1}
              borderRadius='xs'
              paddingHorizontal='l'
              paddingVertical='xxs'>
              <Text>Invite</Text>
            </Box>
          </TouchableOpacity>
        )}
        {showMessageIcon && (
          <TouchableOpacity
            onPress={() => onMessagePress && onMessagePress(contact)}>
            <Box accessibilityLabel='Send Message' flexDirection='row'>
              <Icon
                name='MessageSquare'
                variant='s'
                marginRight='xs'
                onPress={() => onMessagePress && onMessagePress(contact)}
              />
              <Text
                variant='webBodySecondary'
                onPress={() => onMessagePress && onMessagePress(contact)}
                textDecorationLine={isHoveredSendMsg ? 'underline' : undefined}
                onMouseEnter={() => setIsHoveredSendMsg(true)}
                onMouseLeave={() => setIsHoveredSendMsg(false)}>
                Send Message
              </Text>
            </Box>
          </TouchableOpacity>
        )}
      </Box>
    </TouchableOpacity>
  );
};

export default memo(Contact);
