import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import FileCard from '@components/Files/FileCard';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { CollapsableSectionList } from '@components/shared/CollapsableSectionList';
import Icon from '@components/shared/Icon/Icon';
import { Document, Task, TaskSortOption } from '@graphql/generated';
import { useListProjectDocumentsFromQuery } from '@hooks/useListProjectDocumentsFromQuery';
import { useListTasksFromQuery } from '@hooks/useListTasksFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { ProjectFilesProps } from '@screens/Projects/ProjectFiles.web';
import { openInNewTab } from '@utils/fileUtils';
import { filterByDateRange } from '@utils/tasks';

type SectionData = {
  sectionHeader: Task;
  data: Document[];
};

export const ProjectTaskFiles: React.FC<ProjectFilesProps> = ({
  projectId,
  ownerId,
  sortBy,
  isSelectMode,
  deleteloader,
  onSelectedModeChange,
  selectedList,
  handelSelectedItem,
  dateRange,
}) => {
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const [taskIdsOfProject, setTaskIdsOfProject] = useState<Task['id'][]>([]);

  const { isPaid, setPaid } = useMe();
  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      setShowNotPaidConfirmModal(true);

      return true;
    }
    return false;
  };

  const [loadingMore, setLoadingMore] = useState(false);
  const { documents, loading, refetch, pageInfo, fetchFromCursor } =
    useListProjectDocumentsFromQuery({
      projectIds: [projectId],
      ownerIds: ownerId ? [ownerId] : undefined,
      sortBy,
      onFetchMoreCompleted: () => {
        setLoadingMore(false);
      },
    });

  const filteredDocuments = dateRange
    ? filterByDateRange(documents, ({ createdAt }) => createdAt, dateRange)
    : documents;

  const renderFooterUI = () => {
    return (
      <Box marginBottom='listFooter'>
        {loadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  const { tasks } = useListTasksFromQuery({
    projectIds: [projectId],
    skip: !projectId,
    first: 20,
    sortBy: TaskSortOption.NameAsc,
    onCompleted: () => {
      const ids = tasks?.map((t) => t.id) || [];
      setTaskIdsOfProject(ids);
    },
  });

  useEffect(() => {
    refetch({
      projectIds: [projectId],
      taskIds: taskIdsOfProject,
      sortBy,
    });
  }, [taskIdsOfProject, sortBy]);

  const removeDuplicates = (docs: Document[]) => {
    return docs?.reduce((doc: Document[], current) => {
      if (!doc.find((item) => item.id === current.id)) {
        doc.push(current);
      }
      return doc;
    }, []);
  };

  const sectionData = () => {
    if (tasks?.length == 0 || filteredDocuments?.length == 0) {
      return [];
    }

    const section = tasks?.map((i): SectionData => {
      return {
        sectionHeader: i,
        data: [],
      };
    });

    const docs = removeDuplicates(filteredDocuments);

    docs &&
      docs.forEach((d) => {
        const taskIdsOfDoc =
          d?.taskDocuments?.map((t) => {
            if (
              t.task &&
              !section.some((s) => s.sectionHeader.id === t.task?.id)
            ) {
              section.push({
                sectionHeader: t.task,
                data: [],
              });
            }
            return t.task?.id;
          }) || [];
        taskIdsOfDoc &&
          taskIdsOfDoc.forEach((taskId) => {
            section
              ?.find((s) => s.sectionHeader?.id === taskId)
              ?.data.push({
                ...d,
                id: taskId + '-' + d.id,
                selectedTaskFileId: d.taskDocuments?.filter((item) => {
                  return item.task.id == taskId;
                }),
              });
          });
      });
    const sectionDocumentsByTask =
      section?.filter((m) => m.data.length > 0) || [];
    return sectionDocumentsByTask;
  };

  if ((loading && tasks?.length < 1) || deleteloader)
    return <ActivityIndicatorLoading />;

  const sectionHeader = (item: Task) => {
    return (
      <>
        <Box
          backgroundColor='black'
          height={24}
          alignItems='center'
          flexDirection='row'
          justifyContent='center'
          borderRadius='xxs'
          paddingHorizontal='xxs'>
          <Icon name='Hash' color='white' variant='s' />
          <Text marginLeft='xxs' variant='labelSmall' color='white'>
            {tasks?.find((i) => i.id === item.id)?.name || item.name}
          </Text>
        </Box>
        <Box flex={1}></Box>
      </>
    );
  };

  const renderItem = (item: Document) => {
    return (
      <Box
        key={item.id}
        paddingVertical='s'
        paddingLeft='m'
        marginLeft='xxs'
        borderLeftColor='black'
        borderLeftWidth={1}>
        <FileCard
          document={item}
          onPress={() => {
            if (showNotPaidModal(item)) {
              return;
            }

            if (isSelectMode) {
              handelSelectedItem(item);
            } else {
              setPreviewDocuments([item]);
              setActiveIndex([item].length - 1);

              if (item.contentType?.startsWith('image/')) {
                return setImageCarouselVisible(true);
              } else {
                return openInNewTab(item);
              }
            }
          }}
          showTags={false}
          selectedList={selectedList}
          isSelectMode={isSelectMode}
          onLongPress={() => {
            onSelectedModeChange(true);
            handelSelectedItem(item);
          }}
        />
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <CollapsableSectionList<Task, Document>
        ListHeaderComponent={() => <Box />}
        data={sectionData()}
        renderSectionHeader={(item) => sectionHeader(item)}
        renderItem={(item) => renderItem(item)}
        dataListHeaderStyle={{ marginVertical: 'm' }}
        ListFooterComponent={renderFooterUI()}
        fetchMore={() => {
          if (pageInfo?.hasNextPage ?? false) {
            setLoadingMore(true);
            pageInfo?.hasNextPage && fetchFromCursor();
          }
        }}
        fromChatTags={false}
        onEndReachedThreshold={0.5}
        renderEmptyComponent={<EmptyStateNoFiles />}
      />
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Task'
        />
      )}
      {isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
    </Box>
  );
};
